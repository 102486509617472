import React, { Component } from 'react';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
} from 'react-router-dom'
import TopBar from './TopBar';
import './App.css';
import { CSSTransitionGroup } from 'react-transition-group'

import posed, { PoseGroup } from 'react-pose'
import { easing, transform, decay, spring } from 'popmotion'
import animationTimings from './common/animationTimings'
import drag from './common/drag'
import {
  MdArrowBack,
  MdArrowDownward,
  MdArrowForward,
  MdArrowUpward
} from "react-icons/md";
import {
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
  IoLogoFacebook,
  IoLogoYoutube,
  IoLogoPinterest,
  IoLogoFlickr,
} from "react-icons/io"
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import Swipeable from 'react-swipeable';
import { Helmet } from 'react-helmet';
import 'babel-polyfill'
import PropTypes from "prop-types";
import ReactGA from 'react-ga';

const { interpolate } = transform;
const shortid = require('shortid');


class App extends Component {
  render() {
    return (
      <div className="app">
        <header className="app-header">
          <TopBar />
        </header>
        <Routes />
      </div>
    );
  }
}


const Routes = () => {
  return (
    <Switch>
      <Route path='/x' render={() => (
        <div>
          <hr />
          <h3>geheime url</h3>
          <hr />
        </div>
      )} />
      {/*<Redirect from='/projecten' to='/projecten:alias'/>*/}
      <Redirect exact from='/projecten' to='/projecten/categorie/homepage/'/>
      <Route exact path='/projecten' render={() => (
        <div>
          <hr />
          <h3>deze redirect naar eerste uit projecten</h3>
          <hr />
        </div>
      )} />
      <Route exact path='/:alias' render={ (props) =>
        <NodeContainer {...props} is_category={false} is_project={false} /> }
      />
      {/*<Route exact path='/projecten/:alias/:slide_index' component={Slide} />*/}
      <Route exact path='/projecten/categorie/:categorie/:alias?' render={ (props) =>
        <NodeContainer {...props} is_category={true} is_project={true} /> }
      />
      { /*<Route path='/projecten/:alias' component={ProjectContainer} /> */}
      <Route path='/projecten/:alias' render={ (props) =>
        <NodeContainer {...props} is_category={false} is_project={true} /> }
      />
      <Redirect exact from='/' to='/projecten/categorie/homepage/'/>

      <Route render={({ location }) => (
        <div className='ui inverted red segment'>
          <hr />
          <h3>
            Error! No matches for <code>{location.pathname}</code>
          </h3>
          <hr />
        </div>
      )} />
    </Switch>
  )
};

// https://popmotion.io/pose/learn/custom-transitions/
// itemProps is responsible for the X axis
  // this is because of how data is loaded in the component and 
  // thus how the data model navigates to the next slide (up/down) or next project (left/right)
// SlideProps is responsible for the Y axis

// animationTimings in the place where shared value are stored and changed (./common/animationTimings.js)

const itemProps = {
  // before entering position element to the side of the middle
  // also set opacity to 0
  preEnter: {
    x: (element) => { 
      let direction = element.children.props.direction
      if (direction === 'left') return -animationTimings.preEnterPosition
      if (direction === 'right') return animationTimings.preEnterPosition
    },
    opacity: 0,
  },
  // on entering position element to center
  // set opacity to 1
  // use a string transition for the x axis movement
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        type: animationTimings.type, 
        stiffness: animationTimings.stiffness, 
        damping: animationTimings.damping,
        duration: animationTimings.duration,
        ease: easing.linear
      },
      opacity: {
        duration: animationTimings.duration,
        ease: easing.linear
      }
    }
  },
  // on exit fade out with opacity
  exit: {
    x: (element) => { 
      let direction = element.children.props.direction
      if (direction === 'left') return animationTimings.preEnterPosition
      if (direction === 'right') return -animationTimings.preEnterPosition
    },
    opacity: 0,
    transition: {
      opacity: {
        duration: animationTimings.duration,
        ease: easing.linear
      }
    }
  },
  props: {
    slide_forward: true
  }
}

const Item = posed.div(itemProps)

// SlideProps is responsible for the Y axis
const SlideProps = {
  // draggable allows dragging in all directions (both x and y)
  draggable: true,
  // before entering offset element for slide in animation
  preEnter: {
    y: (element) => {
      let direction = element.children.props.direction      
      if (direction === 'top') return -(animationTimings.preEnterPosition/4)
      if (direction === 'bottom') return (animationTimings.preEnterPosition/4)
    },
    opacity: 0,
  },
  // on entering position element to center
  // set opacity to 1
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      y: {
        type: animationTimings.type, 
        stiffness: animationTimings.stiffness, 
        damping: animationTimings.damping,
        duration: animationTimings.duration,
        ease: easing.linear
      },
      opacity: {
        duration: animationTimings.duration,
        ease: easing.linear
      }
    }
  },
  // springback when drag ends (visible when not animating to next project/slide)
  dragEnd: { 
    transition: ({ from, to, velocity }) =>
    spring({ from, to, velocity, stiffness: 250, damping: 50 }) 
  },
  // fade out based on position on the x axis (while dragging)
  // interpolate
  // endFade,         startFade,      startFade,      endFade
  // endOpacity,   startOpacity,   startOpacity,   endOpacity
  passive: {
    opacity: ['x', interpolate(
      [-drag.outside, -drag.inside, drag.inside, drag.outside], 
      [            0,            1,           1,            0]
    )],
  },
}
const Slides = posed.div(SlideProps)

class NodeContainer extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      nodes: [],
      alias: undefined,
      title: undefined,
      is_project: props.is_project,
      is_category: props.is_category,
      fetch_data: false,
      categorie: undefined,
      insta_data: undefined,
      projectenoverzichtBool: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log('getDerivedStateFromProps', nextProps.match.params.alias, prevState.alias)
    // console.log('getDerivedStateFromProps', nextProps)

    let page = nextProps.match.params.alias;
    ReactGA.initialize('UA-2461875-14');
  
    if (page === undefined) {
      ReactGA.pageview(`/homepage`);
    } else {
      ReactGA.pageview(`/${nextProps.match.params.alias}`);
    }
    

    // voor categorie
    if (nextProps.is_category &&
        nextProps.match.params.categorie !== prevState.categorie) {
      return {
        ...prevState,
        is_project: nextProps.is_project,
        fetch_data: true,
        alias: nextProps.match.params.alias,
        categorie: nextProps.match.params.categorie
      }
    }

    // voor projecten
    if (nextProps.match.params.alias !== prevState.alias) {
      return {
        ...prevState,
        is_project: nextProps.is_project,
        fetch_data: true,
        alias: nextProps.match.params.alias,
        categorie: nextProps.match.params.categorie
      }
    }
    // console.log('nextprops nodecontainer: ', nextProps)

    return prevState;
  }

  componentDidMount() {
    this.updateNodes();
  }

  getUrl() {  
    if (this.props.is_category) {
      // console.log('getUrl', this.state.categorie)
      let cat_id = this.state.categorie;
      console.log(cat_id);
      
      return '//backend.borrenstaalenhoef.nl/api/projecten/categorie/' + cat_id;
    }
    else if (this.props.is_project) {
      return '//backend.borrenstaalenhoef.nl/api/projecten';
    }
    else {
      return '//backend.borrenstaalenhoef.nl/'+ this.state.alias + '?_format=json';
    }
  }



  updateNodes() {
    // console.log('updatenodes: fetch data: ', this.state.fetch_data)
    // console.log('updatenodes: is_project: ', this.state.is_project)
    // console.log('updatenodes: is_category: ', this.state.is_category)

    if (this.state.fetch_data) {
        const url = this.getUrl();

        axios.all([
          axios.get(url),
          axios.get('//backend.borrenstaalenhoef.nl/api/paragraph/all'),
          axios.get('//backend.borrenstaalenhoef.nl/api/taxonomie_v2'),
        ])
        .then(axios.spread((response, metaTags, taxonomie) => {
          const data = response.data;
          const nodes = response.data;                    

          var taxonomieArray = [];
          for (let index = 0; index < taxonomie.data.length; index++) {
            const element = taxonomie.data[index].name;
            taxonomieArray.push(element)
          }    
          

          if (this.state.is_project) { 
            // console.log('set state project')

            this.setState({
              ...this.state,
              nodes: nodes,
              metaTags: metaTags.data,
              search: false,
              projectenoverzichtBool: false,
            })
            document.body.classList.remove('dark');
            document.body.classList.remove('light_search');


          } else if(this.state.alias === "projectenoverzicht") {
            console.log('set state projectenoverzicht')
            
            document.body.classList.remove('light_search');
            // document.body.classList.remove('dark');
            document.body.classList.add('dark');

            function chunkArray(myArray, chunk_size){
              var results = [];
              
              while (myArray.length) {
                  results.push(myArray.splice(0, chunk_size));
              }
              
              return results;
            }
            var result = chunkArray(metaTags.data, Math.ceil(metaTags.data.length/3));          

            this.setState({
              ...this.state,
              nid: data.nid[0].value,
              body: data.body[0].processed,
              title: data.title[0].value,
              nodes: result,
              taxonomie: taxonomieArray,
              search: false,
              projectenoverzichtBool: true,
            })





          } else if(this.state.alias === 'ontdek') {
            console.log('set state ontdek')
            
            document.body.classList.add('light_search');
            document.body.classList.remove('dark');

            function chunkArray(myArray, chunk_size){
              var results = [];
              
              while (myArray.length) {
                  results.push(myArray.splice(0, chunk_size));
              }
              
              return results;
            }
            var result = chunkArray(metaTags.data, Math.ceil(metaTags.data.length/3));          

            this.setState({
              ...this.state,
              nid: data.nid[0].value,
              body: data.body[0].processed,
              title: data.title[0].value,
              nodes: result,
              taxonomie: taxonomieArray,
              search: true,
              projectenoverzichtBool: false,
            })

          } else {   
            // console.log('over-ons');
            // console.log('set state overons')

            
            this.setState({
              ...this.state,
              nid: data.nid[0].value,
              body: data.body[0].processed,
              contact: data.field_contact[0].processed,
              projectenoverzicht: data.field_projectoverzicht[0].processed,
              uitgelicht: data.field_uitgelicht[0].processed,
              linkedin: data.field_linkedin[0].processed,
              title: data.title[0].value,
              taxonomie: taxonomieArray,
              search: false,
              projectenoverzichtBool: false,
            })
            document.body.classList.add('dark');


            return axios.get('https://api.instagram.com/v1/users/self/media/recent/?access_token=6630211598.a712317.d3799719a9164e5798fae9a5b10c1c29')
              .then(instagram => {
                // We also need instagram data for the Over-Ons pagina
                const nodes = metaTags.data;
                const insta_data = instagram.data;
                // console.log('instagram', instagram);
                

                if (!this.state.is_project) {
                  this.setState({
                    ...this.state,
                    nodes: nodes,
                    insta_data: insta_data
                    // insta_data: 2
                  })
                }
              })
          }
        }))
        .catch(error => {       
          console.log(error)
        });
    }
    this.setState({fetch_data: false})
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('NodesContainer did update')
    if (this.state.fetch_data) {
      this.updateNodes();

    }
  }

  render() {    
    // check url
    // if projecten > dan naar volgende if:
    //   if categorie, dan naar props doorgeven aan class die categorie url ophaalt en pos bepaalt
    //   en boel rendert op basis van content node (HOC?) en content paragraps.
    // else
    //   dan props doorgeven aan class die node rendert (zelfde HOC?) en linkedin spul ophaalt enzo
    if (this.props.is_project) {
        return (
          <ProjectContainer
            nodes={this.state.nodes}
            alias={this.props.match.params.alias}
            is_project={this.props.is_project}
            is_category={this.props.is_category}
            categorie={this.state.categorie}
          />
        );

    } else if (this.state.search) {
      // console.log('search')
      return (
        <div className="container no-overflow">          
          <div className="grid">
            <div className="column_1">
                <h2 className='projecten' dangerouslySetInnerHTML={{__html: this.state.title}}></h2>
                <div className='projecten' dangerouslySetInnerHTML={{__html: this.state.body}}></div>
                <Autocomplete
                  suggestions={this.state.taxonomie}
                />
            </div>
          </div>
        </div>
      )

    } else if (this.state.projectenoverzichtBool) {
      // console.log('projectoverzicht', this.state)
      var count = 0;

      const projects1 = this.state.nodes[0].map((project) =>
        <div className="project" key={shortid.generate()}>
          <h2>{project.field_titel}</h2>
          <h3>{project.field_subtitel}</h3>
        </div>
      );

      const projects2 = this.state.nodes[1].map((project) =>
        <div className="project" key={shortid.generate()}>
          <h2>{project.field_titel}</h2>
          <h3>{project.field_subtitel}</h3>
        </div>
      );

      const projects3 = this.state.nodes[2].map((project) =>
        <div className="project" key={shortid.generate()}>
          <h2>{project.field_titel}</h2>
          <h3>{project.field_subtitel}</h3>
        </div>
      );


      return (
        <div className="container no-overflow">          
          <div className="grid">
            <div className="column">
              <h2 className='projecten'>Projectenoverzicht</h2>
              <div className='projectenlijst'>{projects1}</div>
            </div>
            <div className="column">
              <h2 className='projecten'></h2>
              <div className='projectenlijst'>{projects2}</div>
            </div>
            <div className="column">
              <h2 className='projecten'></h2>
              <div className='projectenlijst'>{projects3}</div>
            </div>
          </div>
        </div>
      )

    } else {
      // over-ons pagina
      // console.log('over-ons', this.state.projectenoverzichtBool)
      let insta_img = undefined
      if (this.state.insta_data === undefined) {
        // console.log('insta_data is undefined');
      }
      else {
        insta_img = this.state.insta_data.data[0].images.standard_resolution.url
      }

      return (
        <div className="container">
          <div className="grid">

            <div className="column">
              <div className='body' dangerouslySetInnerHTML={{__html: this.state.body}}></div>
            </div>

            <div className="column remove_bg">
              <div className="block">
                <div className='projectenoverzicht' dangerouslySetInnerHTML={{__html: this.state.projectenoverzicht}}></div>
              </div>

              <div className="block">
                <div className='contact' dangerouslySetInnerHTML={{__html: this.state.contact}}></div>
                <div className='follow'>
                  <span>Volg ons hier </span>
                  <a href="https://www.linkedin.com/company/borren-staalenhoef-architecten/"><IoLogoLinkedin style={{verticalAlign: 'middle'}} /></a>
                  <a href="https://twitter.com/bsaarch"><IoLogoTwitter style={{verticalAlign: 'middle'}} /></a>
                  <a href="https://www.flickr.com/photos/bsaarch/"><IoLogoFlickr style={{verticalAlign: 'middle'}} /></a>
                  <a href="https://www.facebook.com/borrenstaalenhoef/"><IoLogoFacebook style={{verticalAlign: 'middle'}} /></a>
                  <a href="https://www.youtube.com/channel/UCwvN0e4Si6d1zCOW6EBXayg"><IoLogoYoutube style={{verticalAlign: 'middle'}} /></a>
                  <a href="https://www.pinterest.com/BSarchitects/"><IoLogoPinterest style={{verticalAlign: 'middle'}} /></a>
                </div>
              </div>

            </div>

            <div className="column">
              <h2>Social media</h2>
              <h3>
                <a href="https://www.instagram.com/borrenstaalenhoefarchitecten/" target="_blank" rel="noopener noreferrer">
                  <IoLogoInstagram style={{verticalAlign: 'middle'}} /> <span>Instagram</span>
                </a>
              </h3>
              <a href="https://www.instagram.com/borrenstaalenhoefarchitecten/">
                <img src={insta_img} alt='' />
              </a>

              <h3>
                <a href="https://www.linkedin.com/company/borren-staalenhoef-architecten/" target="_blank" rel="noopener noreferrer">
                  <IoLogoLinkedin style={{verticalAlign: 'middle'}} /> <span>LinkedIn</span>
                </a>
              </h3>
              <div className="linkedin" dangerouslySetInnerHTML={{__html: this.state.linkedin}}></div>
              <br />
              


              <div className='uitgelicht' dangerouslySetInnerHTML={{__html: this.state.uitgelicht}}></div>
              <h3>
                <a href="https://twitter.com/bsaarc" target="_blank" rel="noopener noreferrer">
                  <IoLogoTwitter style={{verticalAlign: 'middle'}} /> <span>Twitter</span>
                </a>
              </h3>
              <div className="twitter--embed">
                <TwitterTimelineEmbed
                  sourceType="profile"
                  screenName="bsaarch"
                  theme="dark"
                  options={{height: 400}}
                  // autoHeight
                  linkColor="#ffffff"
                  transparent
                  noHeader
                  noFooter
                  noBorders
                  noScrollbar
                />
              </div>
            </div>

            

          </div>
        </div>
      );
    }
  }
}

class Project extends Component {

  constructor(props) {
    super(props)

    this.state = {
      nid: props.nid,
      data: undefined,
      slides: [],
      slide_index: undefined,
      view_node: undefined,
      slide_forward: true,
      allow_prev_slide: false,
      allow_next_slide: false,
      previous_project: undefined,
      next_project: undefined,
      allow_next_project: false,
      allow_prev_project: false,
      direction: undefined,
    }

    // console.log('slide_index state:', this.state.slide_index);

    this.handlePreviousSlideClick = this.handlePreviousSlideClick.bind(this);
    this.handleNextSlideClick = this.handleNextSlideClick.bind(this);

    this.handlePreviousSlide = this.handlePreviousSlide.bind(this);
    this.handleNextSlide = this.handleNextSlide.bind(this);

    this.handlePreviousHover = this.handlePreviousHover.bind(this);
    this.handleNextHover = this.handleNextHover.bind(this);
  }

  componentWillMount() {
    //axios.get('//backend.borrenstaalenhoef.nl/api/paragraph/'+ this.props.nid)

    let url = '//backend.borrenstaalenhoef.nl'+ this.props.view_node + '?_format=json';
    
    
    axios.get(url)
      .then(response => {       
        // console.log('meta', response.data.field_social_tekst[0] === undefined);

        // this fixes error when fields are empty? the api does not allow this 
        var field_social_tekst = undefined
        var field_social_afbeelding = undefined

        if (response.data.field_social_tekst[0] === undefined) {
          field_social_tekst = 'Borrenstaalenhoef Architecten'
        } else {
          field_social_tekst = response.data.field_social_tekst[0].value
        }

        // console.log('redirect', response.data.field_slides)

        !this.isCancelled && this.setState({
          ...this.state,
          slides: response.data.field_slides,
          slide_index: 0,
          meta_title: response.data.title[0].value,
          meta_description: field_social_tekst,
          meta_image: field_social_afbeelding
        })
      })
      .catch(error => console.log('error', error));
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('test node: ', this.props.nid);
    // console.log('current node: ', this.state.nid);
    // console.log('nextProps: ', nextProps);
    // console.log('nextState: ', nextState);

    const new_nid = nextProps.nid;
    const new_view_node = nextProps.view_node;


    if (nextState.next_project || nextState.previous_project) {
      return true;
    }

    if (new_nid !== this.state.nid) {

      axios.get('//backend.borrenstaalenhoef.nl/'+ new_view_node + '?_format=json')
      .then(response => {
        // console.log('field_slides data: ', response.data.field_slides);
        this.setState({
          ...this.state,
          nid: new_nid,
          view_node: new_view_node,
          slides: response.data.field_slides,
          slide_index: 0
        });
      })
      .catch(error => console.log(error));


      // console.log('field_slides state: ', this.state.slides)

      return true;

    } else if (nextState.slide_index !== this.state.slide_index) {
      return true;

    } else if (nextState.slide_forward !== this.state.slide_forward) {
      return true;
    }

    // const new_slides = nextProps.match.params.slides;
    // console.log('field_slides props: ', this.props.slides);


    return false;
  }

  handlePreviousSlideClick() {
    this.setState({
      ...this.state,
      allow_prev_slide: true,
    }, () => {
      this.handlePreviousSlide(undefined, undefined, true);
    });
  }

  handlePreviousSlide(e, deltaY, isFlick) {
    // check if is first slide
    let first_slide = this.state.slide_index === 0;
    // check if isFlick
    // check if is allowed to slide
    if (!isFlick || !this.state.allow_prev_slide || first_slide) {
      return;
    }

    // set the state
    // set slide_index
    // set transition type
    this.setState({
      ...this.state,
      slide_index: this.state.slide_index-1,
      transition: 'prev-slide',
      next_project: false,
      slide_forward: false,
      direction: 'top'
    })

    // set slide direction to parent
    this.props.handleDirection('top')

  }

  handlePreviousHover() {
    // console.log('handlePreviousHover')
    this.setState({
      ...this.state,
      slide_forward: false,
    })
  }

  handleNextSlideClick() {
    // console.log('handleNextSlideClick');
    this.setState({
      ...this.state,
      allow_next_slide: true,
    }, () => {
      this.handleNextSlide(undefined, undefined, true);
    });
  }

  handleNextSlide(e, deltaY, isFlick) {
    // check if slide is last slide
    let last_slide = this.state.slides.length-1 === this.state.slide_index
    // check if isFlick
    // check if is allowed to slide
    if (!isFlick || !this.state.allow_next_slide || last_slide) {
      return;
    }
    
    // set the state
    // set slide_index
    // set transition type
    this.setState({
      ...this.state,
      slide_index: this.state.slide_index+1,
      transition: 'next-slide',
      next_project: false,
      slide_forward: true,
      direction: 'bottom'
    })

    // set slide direction to parent
    this.props.handleDirection('bottom')
  }

  handleNextHover() {
    // console.log('handleNextHover')

    this.setState({
      ...this.state,
      slide_forward: true,
    })
  }

  nextProject() {
    this.setState({
      ...this.state,
      previous_project: false,
      next_project: true,
      slide_forward: false,
      direction: 'right'
    });

    // set slide direction to parent
    this.props.handleDirection('right')
  }

  previousProject() {
    this.setState({
      ...this.state,
      previous_project: true,
      next_project: false,
      slide_forward: false,
      direction: 'left'
    });

    // set slide direction to parent
    this.props.handleDirection('left')
  }

  dragHandlerX(int) {
    // check if int is bigger than a limit
    // works with positive and negative int

    // go to previous project (drag to right)
    if (int >= drag.inside) {
      // allow next project
      this.setState({
        ...this.state,
        allow_prev_project: true,
        direction: 'left'
      });

      // set slide direction to parent
      this.props.handleDirection('left')
    }

    // go to next project (drag to left)
    if (int <= -drag.inside) {
      // allow next project
      this.setState({
        ...this.state,
        allow_next_project: true,
        allow_prev_project: false,
        direction: 'right'
      });

      // set slide direction to parent
      this.props.handleDirection('right')
    }
  }

  dragHandlerY(int) {
    // check if int is bigger than a limit
    // works with positive and negative int

    // go to previous slide (drag to bottom)
    if (int >= (drag.inside / 2)) {
      // allow next slide
      this.setState({
        ...this.state,
        allow_prev_slide: true,
        allow_next_project: false,
        previous_project: false,
        next_project: false,
        slide_forward: false,
        direction: 'top'
      });

      // set slide direction to parent
      this.props.handleDirection('top')
    }

    // go to next slide (drag to top)
    if (int <= -(drag.inside / 2)) {
      // allow next slide
      this.setState({
        ...this.state,
        allow_next_slide: true,
        allow_prev_slide: false,
        direction: 'bottom'
      });

      // set slide direction to parent
      this.props.handleDirection('bottom')
    }
  }

  handleKeyDown(e) {
    // This handles the keypresses in the document
    // Currently only the four arrow keys are used
    // ArrowLeft 37
    // ArrowUp 38
    // ArrowRight 39
    // ArrowDown 40

    switch (e.keyCode) {
      case 37:
        // ArrowLeft

        // set the state
        // set slide_index
        // set transition type
        this.setState({
          ...this.state,
          next_project: false,
          previous_project: true,
          direction: 'left',
          allow_prev_project: true
        });
    
        // // set slide direction to parent
        this.props.handleDirection('left')
        break;
      case 39:
        // ArrowRight

        // set the state
        // set slide_index
        // set transition type
        this.setState({
          ...this.state,
          next_project: true,
          previous_project: false,
          direction: 'right',
          allow_next_project: true
        });
    
        // // set slide direction to parent
        this.props.handleDirection('right')
        break;
      case 38:
        // ArrowUp

        // Check if the current slide is the first slide
        if (this.state.slide_index <= 0) return

        // set the state
        // set slide_index
        // set transition type
        this.setState({
          ...this.state,
          previous_project: false,
          next_project: false,
          allow_prev_slide: true,
          direction: 'top',
          slide_forward: false,
          slide_index: this.state.slide_index -1
        });
    
        // set slide direction to parent
        this.props.handleDirection('top')
        break;
      case 40:
        // ArrowDown 
        
        // Check if the current slide is the last slide
        if ((this.state.slide_index + 1) >= this.state.slides.length) return

        // set the state
        // set slide_index
        // set transition type
        this.setState({
          ...this.state,
          previous_project: false,
          next_project: false,
          allow_next_slide: true,
          direction: 'bottom',
          slide_forward: true,
          slide_index: this.state.slide_index +1
        });
    
        // set slide direction to parent
        this.props.handleDirection('bottom')
        break;
    
      default:
        // any key not in the switch
        break;
    }
  }

  componentDidMount() {
    // usually when using an event listener "this" results into the #document
    // when binding the event to this in the componentDidMount 
    // results "this" into the react component
    this.handleKeyDown = this.handleKeyDown.bind(this)
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.isCancelled = true;
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    // check if next_project and the related url are defined
    // check if its allow to go to the next project
    if (this.state.next_project && this.props.next_project_url !== undefined && this.state.allow_next_project) {
      // redirect with a push state html tag      
      return <Redirect push to={`${ this.props.next_project_url }`} />;

      // check if previous_project and its related url are defined
      // check if its allow to go the the previous project
    } else if (this.state.previous_project && this.props.prev_project_url !== undefined && this.state.allow_prev_project) {
      // redirect with a push state html tag
      return <Redirect push to={`${ this.props.prev_project_url }`} />;
    }

    let current_slide = undefined;

    // define current slide if it has current slides
    if (this.state.slides.length !== 0) {
      current_slide = this.state.slides[this.state.slide_index];
    } else {
      // console.log('slide_state', 'no slide defined')
    }

    // set index of previous slide
    let prev_slide = undefined;
    if (this.state.slide_index !== 0) {
      prev_slide = this.state.slide_index;
    }

    // set index of next slide
    let next_slide = undefined;
    if (this.state.slide_index !== this.state.slides.length-1) {
      next_slide = this.state.slide_index;
    }

    // define slideforward based on current state
    let slide_forward = this.state.slide_forward;

    // define dragging based on current state (not used?)
    // let dragging = this.state.dragging;
    return (
      <React.Fragment>
        <MetaTags
          title={this.state.meta_title}
          description={this.state.meta_description}
          image={this.state.meta_image}
        />
        <div className='slideshow' tabIndex="1" onKeyDown={this.handleKeyDown}>

            {prev_slide !== undefined && current_slide !== undefined &&
              <a className="prev button" onMouseUp={this.handlePreviousSlideClick} onMouseDown={this.handlePreviousHover}><MdArrowUpward style={{verticalAlign: 'middle'}} /></a>
            }

            {next_slide !== undefined && current_slide !== undefined &&
              <a className="next button" onMouseUp={this.handleNextSlideClick} onMouseDown={this.handleNextHover}><MdArrowDownward style={{verticalAlign: 'middle'}} /></a>
            }

          {current_slide !== undefined &&
            <PoseGroup preEnterPose="preEnter">
              <Slides
                withParent={false}
                className="card--slide"
                key={current_slide.target_id}
                slide_forward={slide_forward}
                onValueChange={{
                  x: x => this.dragHandlerX(x),
                  y: y => this.dragHandlerY(y)
                }}
                direction={ this.state.direction }
              >

                <Swipeable
                  className="swipe"
                  trackMouse
                  style={{ touchAction: 'none' }}
                  preventDefaultTouchmoveEvent
                  onSwipedUp={this.handleNextSlide}
                  onSwipedDown={this.handlePreviousSlide}
                  onSwipingUp={()=>this.handleNextHover()}
                  onSwipingDown={()=>this.handlePreviousHover()}
                  onSwipedLeft={()=>this.nextProject()}
                  onSwipedRight={()=>this.previousProject()}
                  direction={ this.state.direction }
                >

                  <Slide
                    key={current_slide.target_id}
                    target_id={current_slide.target_id}
                  />
                </Swipeable>
              </Slides>
            </PoseGroup>
          }
        </div>
      </React.Fragment>
    )
  }
}

class Slide extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: undefined,
      direction: this.props.direction,
      imageStatus: "loading"
    }
  }

  componentDidMount() {
    axios.get('//backend.borrenstaalenhoef.nl/api/paragraph/' + this.props.target_id)
      .then(response => {
        const data = response.data[0];       
        let data_type = undefined;

        // format data fields properly
        if (data.field_titel !== "") {
          data_type = 'text';
        } else if (data.field_afbeelding !== "") {
          data_type = 'image';
        } else if (data.field_video !== "") {
          data_type = 'video';
        } else if (data.field_data_titel !== "") {
          data_type = 'data';
        }

        this.setState({
          data: data,
          data_type: data_type
        })       
      })
      .catch(error => console.log(error));
  }

  handleImageLoaded() {
    this.setState({ imageStatus: "loaded" });
  }

  handleImageErrored() {
    this.setState({ imageStatus: "failed to load" });
  }

  render() {
    if (this.state.data === undefined) {
      return (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      );
    }
    console.log(this.state.imageStatus)

    switch (this.state.data_type) {
      case 'text':
        return (
          <div className='slide slide--text'>
            <h1 className='slide--text__title'>{this.state.data.field_titel}</h1>
            <h2 className='slide--text__subtitle'>{this.state.data.field_subtitel}</h2>
            <div className='slide--text__text' dangerouslySetInnerHTML={{__html: this.state.data.field_tekst}}></div>
          </div>
        ); 
      case 'image':
        return (
          <div className='slide slide--image'>
            <img src={`https://backend.borrenstaalenhoef.nl/${ this.state.data.field_afbeelding }`} alt='' 
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageErrored.bind(this)}/>
          </div>
        );
      case 'video':
        // console.log('videourl original: ', this.state.data.field_video);
        const video = this.state.data.field_video.match(/"([^"]+)"/)[1];
        // const video = this.state.data.field_video.href;
        // console.log('videourl href: ', video);
        if (video.match('youtube')) {
          const video_id = video.match('v\s*\=\s*(.+)')[1];

          return (
            <div className='slide slide--video'>
              <iframe id="ytplayer" title="Borren Staalenhoef video" type="text/html"
                src={`https://www.youtube.com/embed/${ video_id }?autoplay=1&controls=0&loop=1&modestbranding=1&frameborder="0"&disablekb=0&playlist=${ video_id }&mute=1&rel=0`}
                frameBorder="0"></iframe>
            </div>
          )
        } else {
          return (
            <div className='error'>Deze video is geen YouTube link!</div>
          )
        }
      case 'data':
        return (
          <div className='slide slide--data'>
            <h1 className='slide--data__title'>{this.state.data.field_data_titel}</h1>
            <h2 className='slide--data__subtitle'>{this.state.data.field_data_subtitel}</h2>
            <div className='slide--data__list'>

              <div className="slide--data__row">
                {this.state.data.field_rij_1_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_1_label}
                </span>}
                {this.state.data.field_rij_1.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_1}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_2_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_2_label}
                </span>}
                {this.state.data.field_rij_2.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_2}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_3_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_3_label}
                </span>}
                {this.state.data.field_rij_3.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_3}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_4_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_4_label}
                </span>}
                {this.state.data.field_rij_4.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_4}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_5_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_5_label}
                </span>}
                {this.state.data.field_rij_5.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_5}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_6_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_6_label}
                </span>}
                {this.state.data.field_rij_6.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_6}
                </span>}
              </div>
              <div className="slide--data__row">
                {this.state.data.field_rij_7_label.length > 0 && <span className="slide--data__row-label">
                  {this.state.data.field_rij_7_label}
                </span>}
                {this.state.data.field_rij_7.length > 0 && <span className="slide--data__row-input">
                  {this.state.data.field_rij_7}
                </span>}
              </div>
              
            </div>
          </div>
        );
      default:
        // not used
    }

    return (
      <div className="loader"></div>
    );
  }
}

class ProjectContainer extends Component {
  constructor(props) {
    super(props)

    // connect handleDirection to the contructor?
    // https://stackoverflow.com/questions/38394015/how-to-pass-data-from-child-component-to-its-parent-in-reactjs
    this.handleDirection = this.handleDirection.bind(this)

    // console.log(props);

    this.state = {
      nodes: [],
      alias: undefined,
      current_index: 0,
      base_path: undefined,
      categorie: undefined,
      slide_forward: true,
      direction: undefined
    }
    // console.log('current_index state: ', this.state.current_index);

    this.handlePreviousProject = this.handlePreviousProject.bind(this);
    this.handleNextProject = this.handleNextProject.bind(this);
    this.handlePreviousHover = this.handlePreviousHover.bind(this);
    this.handleNextHover = this.handleNextHover.bind(this);
  }

  handlePreviousProject() {
    // console.log('going to prev project by click');
    // console.log('direction', 'left by click')
    this.setState({
      ...this.state,
      next_project: false,
      slide_forward: false,
      direction: 'left'
    })
  }

  handlePreviousHover() {
    // console.log('handlePreviousHover')

    this.setState({
      ...this.state,
      slide_forward: false
    })
  }

  handleNextProject() {
    // console.log('going to project by click');
    // console.log('direction', 'right by click')

    this.setState({
      ...this.state,
      slide_forward: true,
      direction: 'right'
    })
  }

  handleNextHover() {
    // console.log('handleNextHover')

    this.setState({
        ...this.state,
        slide_forward: true
    })
  }

  handleDirection(value) {

    this.setState({
      ...this.state,
      direction: value
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newNodes = nextProps.nodes;
    const oldNodes = prevState.nodes;

    const new_alias = nextProps.alias;

    let new_id = newNodes.findIndex(function(item) {
      if (item.view_node === undefined) return false
      
      const item_viewnode = item.view_node.split('/');
      const item_alias = item_viewnode[item_viewnode.length-1];

      if (new_alias === item_alias) {
        return true;
      }
      return false;
    });

    let base_path = '/projecten'
    if (nextProps.is_category) {
      base_path = `/projecten/categorie/${ nextProps.categorie }`;
    }
    // console.log('nextProps.alias', nextProps.alias)
    if (nextProps.alias === undefined) {
      new_id = 0;
    }    

    return {
      ...prevState,
      nodes: newNodes,
      current_index: new_id,
      alias: nextProps.alias,
      base_path: base_path,
      lastproject: (nextProps.nodes.length === new_id+1),
      firstproject: (new_id === 0)
    };
  }

  render() {
    // Show a loader is the data (nodes) haven't been loaded yet
    if (this.state.nodes.length === 0) {
      return (
        <div className="loader"></div>
      );
    }

    // Set the current item based on the current index in the nodes array
    var item = undefined

    // check if it uses a project object data type
    if (this.state.nodes[0].nid !== undefined) {
      // regular navigation
      item = this.state.nodes[this.state.current_index];
    } else {
      // navigation from over-ons back to project page
      // return false triggers a refresh / rebuild of page?
      return false
    }

    // set the previous alias
    let prev_alias = undefined;
    if (this.state.current_index !== 0) {      
      const prev = this.state.nodes[this.state.current_index-1];
      const prev_2 = prev.view_node.split('/');
      prev_alias = prev_2[prev_2.length - 1];
    }

    // set the next alias
    let next_alias = undefined;
    if (this.state.current_index !== this.state.nodes.length-1) {
      const next = this.state.nodes[this.state.current_index+1];
      const next_2 = next.view_node.split('/');
      next_alias = next_2[next_2.length - 1];
    }


    let slide_forward = this.state.slide_forward;

    if (slide_forward && next_alias === undefined) {
      slide_forward = false;
    } else if (!slide_forward && prev_alias === undefined) {
      slide_forward = true;
    }

    const prev_url = prev_alias ? `${ this.state.base_path }/${ prev_alias }` : undefined;
    const next_url = next_alias ? `${ this.state.base_path }/${ next_alias }` : undefined;

    return (
      <div className='showreel'>
          {prev_alias !== undefined &&
            // handler/prop met x waarde voor posed props, zodat bij rerender van <Project> het project van links inschuift
            <Link
              className='prev button'
              to={`${ this.state.base_path }/${ prev_alias }`}
              onMouseUp={this.handlePreviousProject}
              onMouseDown={this.handlePreviousHover}
              >
                <MdArrowUpward style={{verticalAlign: 'middle'}} /> vorig project
            </Link>
          }

          {next_alias !== undefined &&
            // handler/prop met x waarde voor posed props, zodat bij rerender van <Project> het project van rechts inschuift
            // <Link className='next' to={`${ this.state.base_path }/${ next_alias }`} onClick={this.handleProjectControls} direction="right">
            <Link
              className='next button'
              to={`${ this.state.base_path }/${ next_alias }`}
              onMouseUp={this.handleNextProject}
              onMouseDown={this.handleNextHover}
            >
              <MdArrowDownward style={{verticalAlign: 'middle'}} /> volgend project
            </Link>
          }
        <div id={item.nid} >
          <PoseGroup preEnterPose="preEnter">
            <Item
              className="card--project"
              key={item.nid}
              slide_forward={slide_forward}
            >
              <Project
                view_node={`${item.view_node}`}
                alias={`${ this.state.alias }`}
                nid={`${ item.nid }`}
                prev_project_url={ prev_url }
                next_project_url={ next_url }
                handleDirection={ this.handleDirection }
                direction={ this.state.direction }
                />
            </Item>
          </PoseGroup>
        </div>
      </div>
    )
  }
}

class MetaTags extends Component {
  render() {
    return (
      <Helmet>
        <title>{this.props.title}</title>
        <meta name="description" content={this.props.description} />
      </Helmet>
    )
  }
}


class Autocomplete extends Component {
  // https://alligator.io/react/react-autocomplete/
  // //backend.borrenstaalenhoef.nl/api/taxonomie_v2
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: "",
      // allowing to redirect to route
      redirect: false
    };
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
      redirect: false
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      redirect: false // remove links?
    });
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    // also redirect the user to the collection
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
        redirect: true
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        redirect
      }
    } = this;

    let suggestionsListComponent;

    if (redirect && filteredSuggestions.length >= 1) {
      console.log(this.state)
      return (
        <Redirect to={'/projecten/categorie/' + userInput} />
      )
    }

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <a 
                  href={"/projecten/categorie/" + suggestion}
                  className={className}
                  key={suggestion}
                >
                  {suggestion}
                </a>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            Geen resultaten
          </div>
        );
      }
    }

    return (
      <div className="autocomplete_form">
        <h2>Ontdek</h2>
        <p>
          Vul een zoekterm in
        </p>
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          placeholder="Ontdek collecties"
        />
        {suggestionsListComponent}
      </div>
    );
  }
}




export default App;
