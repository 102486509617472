import React from 'react';

import SvgLogo from './SvgLogo'
import { NavLink } from 'react-router-dom';

// import logo from './logo.svg';

const TopBar = () => (
  <div
    className='mainmenu'>
    <div className='item'>
      <a className='Home' href="/"><SvgLogo /></a>
      {
        // <a className='Home' href="/"><img src={logo} className="app-logo" alt="logo" /></a>
      }
    </div>
    <div className='rightmenu'>

      <NavLink className='item' to='/ontdek'>
        ontdek
      </NavLink>

      <NavLink className='item' to='/over-ons'>
        over ons
      </NavLink>

      </div>
  </div>
);

export default TopBar;
