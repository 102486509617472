import React from 'react';

class SvgLogo extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      // logo
      <svg xmlns="http://www.w3.org/2000/svg" className="app-logo" viewBox="0 0 53 22.4"><path d="M4.7 4.2h4.4c1.7 0 3.2 0.4 3.2 2.5 0 1.8-1.2 2.6-2.9 2.6H4.7V4.2zM0 21.9h10.4c3.9 0 7.6-1.9 7.6-6.2 0-2.7-1.3-4.7-3.9-5.4C16 9.4 17 7.9 17 5.8c0-4-2.8-5.3-6.8-5.3H0V21.9zM4.7 12.4h5.1c2 0 3.4 0.9 3.4 3.1s-1.6 2.8-3.5 2.8h-5C4.7 18.3 4.7 12.4 4.7 12.4z"/><path d="M24.3 14.8c0 2.9 2.2 4 4.7 4 1.7 0 4.2-0.5 4.2-2.7 0-2.3-3.2-2.7-6.4-3.5s-6.4-2.1-6.4-6.1c0-4.4 4.1-6.5 8-6.5C32.9 0 37 2 37 6.9h-4.6c-0.1-2.6-2-3.2-4.2-3.2 -1.5 0-3.2 0.6-3.2 2.4 0 1.6 1 1.9 6.4 3.2 1.6 0.4 6.4 1.4 6.4 6.2 0 3.9-3.1 6.9-8.9 6.9 -4.7 0-9.2-2.3-9.1-7.6C19.8 14.8 24.3 14.8 24.3 14.8z"/><path d="M45.7 0.5l-6.3 15.8h1l2-5.1H50l2 5.1h1L46.7 0.5H45.7zM42.7 10.4l3.5-8.9 0 0 3.4 8.9H42.7z"/></svg>
    );
  }
}

export default SvgLogo;
